.fsa-body-bg{
    background-image: linear-gradient(#1a748e, #55c4d7, #f0df99, #d38e31, #992915);
    color: whitesmoke;
}

.add-text-shadow{
     text-shadow: 1px 1px black;
}

.fsa-footer{
    color: whitesmoke;
    text-shadow: 1px 1px #992915;
}

.fsa-footer a:link{
    color: whitesmoke;
}

.fsa-footer a:visited{
    color: darkgray;
}

.fsa-footer a:hover{
    color: #55c4d7;
}

.content{
    height: min-content;
    min-height: 100vh;
}

.bg-light-cust{
    background: #F0DF99FF;
}

.text-light-cust{
    color: #F0DF99FF;
}

.bg-dark-cust{
    background: #992915FF;
}

.text-dark-cust{
    color: #992915FF;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.thing-or-other{
    background: #d85a01;
}

img {
    max-width: 100%;
    max-height: 800px;
    width: auto;
    height: auto;
    display: block;
}